import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { PERSON_SOCIAL_TIMELINE } from "../keys";
import { format } from "date-fns";

const usePersonSocialTimeline = () => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }

  return useQuery({
    queryKey: [PERSON_SOCIAL_TIMELINE, tenant.id, since, until],
    queryFn: () => ApiService.getPersonSocialTimeline(since, until),
    select: (data) => Array.isArray(data.data) ? data.data : [],
    placeholderData: [{data: []}],
    // Reduce stale time to 0 to always fetch fresh data
    staleTime: 0,
    // Cache data for 5 minutes
    cacheTime: 5 * 60 * 1000,
    // Refetch on window focus
    refetchOnWindowFocus: true,
    // Refetch on reconnect
    refetchOnReconnect: true,
    // Poll every 5 seconds to keep timeline fresh
    refetchInterval: 5000,
    refetchIntervalInBackground: true
  });
};

export default usePersonSocialTimeline;
