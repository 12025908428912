import { useQuery } from "@tanstack/react-query";
import ApiService from "../../services/ApiService";

const useSegmentQuestionDemographics = (questionCode) => {
  console.log('useSegmentQuestionDemographics called with questionCode:', questionCode);
  
  const query = useQuery({
    queryKey: ['useSegmentQuestionDemographics', questionCode],
    queryFn: async () => {
      console.log('Fetching segment demographics for questionCode:', questionCode);
      const response = await ApiService.getSegmentQuestionDemographics(questionCode);
      console.log('useSegmentQuestionDemographics response:', response);
      return response.data;
    },
    enabled: !!questionCode,
  });

  console.log('Query state:', {
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    data: query.data,
    enabled: !!questionCode
  });

  return query;
};

export default useSegmentQuestionDemographics;