import { useQuery } from "@tanstack/react-query";
import ApiService from "../../services/ApiService";
import { SPONSOR_ROI } from "../keys";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const useSponsorROI = (sponsorObjectId) => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }

  return useQuery({
    queryKey: [SPONSOR_ROI, tenant.id, sponsorObjectId, startDate, endDate],
    queryFn: () => ApiService.getSponsorROI(since, until, sponsorObjectId),
    select: (data) => data.data,
    placeholderData: { data: { roi: 0, trend: 0, series: [] } },
  });
};

export default useSponsorROI;
