import React from 'react'
import { Spinner } from "reactstrap";
import Highcharts from "highcharts";
import HCMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

HCMore(Highcharts);

const BubbleGraph = ({ xLabel, yLabel, zLabel, useQuery, params }) => {
  const { data, isLoading, isRefetching, isSuccess } = useQuery(...params);

  const chartOptions = () => {
    return {
      exporting: {
        enabled: true,
      },
      credits: { enabled: false },
      chart: {
        type: "bubble",
        plotBorderWidth: 1,
        backgroundColor: 'transparent',
      },
      plotOptions: {
        bubble: {
          minSize: 30,
          maxSize: 60,
          sizeBy: 'area',
          zThreshold: 0,
          tooltip: {
            headerFormat: "",
            pointFormat:
              `${xLabel}: <b>{point.x}</b><br/>
              ${yLabel}: <b>{point.y}</b><br/>
              ${zLabel}: <b>{point.z}</b><br/>`,
          },
        }
      },
      title: {
        text: "",
      },
      xAxis: {
        labels: {
          style: {
            color: 'var(--text-primary)',
          },
        },
        title: {
          text: xLabel,
          style: {
            color: 'var(--text-primary)',
          },
        },
        minPadding: 0.05,
        maxPadding: 0.05,
      },
      yAxis: {
        title: {
          text: yLabel,
          style: {
            color: 'var(--text-primary)',
          },
        },
        labels: {
          style: {
            color: 'var(--text-primary)',
          },
        },
        minPadding: 0.05,
        maxPadding: 0.05,
      },
      series: data,
    };
  };

  return (
    <div key="1">
      {(isLoading || isRefetching) && <Spinner color="primary" size="sm" />}
      {isSuccess && (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions()}
        />
      )}
    </div>
  );
};

export default BubbleGraph;
