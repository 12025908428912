import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { SURVEY_SEGMENT_METRICS } from "../keys";

const useSegmentMetrics = (params = {}) => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [SURVEY_SEGMENT_METRICS, tenant.id, params],
    queryFn: () => ApiService.getSegmentMetricsByQuestionGroup(params),
    select: (response) => response.data,
    placeholderData: { 
      data: {
        series: [],
        xTitle: '',
        yTitle: ''
      }
    },
  });
};

export default useSegmentMetrics;
