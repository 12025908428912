import React from "react";
import numeral from "numeral";
import "numeral/locales";
import { Spinner } from "reactstrap";
import { useTable, useSortBy } from "react-table";
import { useMemo } from "react";
numeral.locale("no");

const Table = ({ useQuery, params }) => {
  const { data, isFetching } = useQuery(...params);

  const columns = useMemo(() => data.headers, [data]);
  const dataRows = useMemo(() => data.rows, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: dataRows,
      },
      useSortBy
    );

  return (
    <>
      {(isFetching) && <Spinner color="primary" />}
      <table
        className="table table-hover table-centered table-nowrap mb-0 text-white table-responsive"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  width={index === 0 ? "40%" : "20%"}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="mdi mdi-chevron-down"></span>
                      ) : (
                        <span className="mdi mdi-chevron-up"></span>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          {/* <tr className="totals">
            {Object.values(totalRow).map((cell) => (
              <td key={cell}>{cell}</td>
            ))}
          </tr> */}
        </tbody>
      </table>
    </>
  );
};

export default Table;
