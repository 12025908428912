import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useSegmentQuestionDemographics from '../../../query/hooks/useSegmentQuestionDemographics';

const DemographicPieChart = ({ questionCode, title, isDarkMode }) => {
  const { data: demographicsData, isLoading } = useSegmentQuestionDemographics(questionCode);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!demographicsData) {
    return <div>No data available</div>;
  }

  const chartData = Array.isArray(demographicsData) ? demographicsData.map(item => ({
    name: item.label,
    y: parseFloat(item.percentage)
  })) : [];

  const chartOptions = {
    chart: {
      type: 'pie',
      backgroundColor: isDarkMode ? '#2c2c2c' : '#ffffff',
    },
    title: {
      text: '',
      style: {
        color: isDarkMode ? '#ffffff' : '#000000'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f}%',
          style: {
            color: isDarkMode ? '#ffffff' : '#000000'
          }
        }
      }
    },
    series: [{
      name: title,
      data: chartData
    }],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        color: isDarkMode ? '#ffffff' : '#000000'
      }
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  );
};

DemographicPieChart.propTypes = {
  questionCode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isDarkMode: PropTypes.bool
};

DemographicPieChart.defaultProps = {
  isDarkMode: false
};

export default DemographicPieChart;
