import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiService from "../../services/ApiService";
import { SPONSOR_CONTRACTS } from "../keys";
import { useSelector } from "react-redux";

const useUpdateSponsorContract = () => {
  const tenant = useSelector((state) => state.auth.tenant);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }) => ApiService.updateSponsorContract(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries([SPONSOR_CONTRACTS, tenant.id]);
    },
  });
};

export default useUpdateSponsorContract;
