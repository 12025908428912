import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import numeral from "numeral";
import { SPONSOR_PERFORMANCE_TABLE } from "../keys";

const useSponsorPerformanceTable = (sponsorObjectId) => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  return useQuery({
    queryKey: [
      SPONSOR_PERFORMANCE_TABLE,
      sponsorObjectId,
      tenant.id,
      startDate,
      endDate,
    ],
    queryFn: () =>
      ApiService.getSponsorPerformanceTable(since, until, sponsorObjectId),
    select: (data) => {
      const rows = data.data.rows.map((row) => {
        return {
          ...row,
          impressions: numeral(row.impressions).format("0,0"),
          value: numeral(row.value).format("0,0"),
          roi: Number(row?.roi ?? 0)?.toFixed(2),
          percentage: row.percentage && numeral(row.percentage).format("0%"),
        };
      });
      data.data.rows = rows;
      return data.data;
    },
    placeholderData: { data: { headers: [], rows: [] } },
  });
};
export default useSponsorPerformanceTable;
