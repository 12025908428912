import React from 'react';
import { useSponsorROI } from "../query/hooks";
import { Spinner } from "reactstrap";

const ROIWidget = ({ sponsorObjectId }) => {
  const { data: roiData, isFetching } = useSponsorROI(sponsorObjectId);

  const formatPercentage = (value) => {
    return `${(value * 100).toFixed(1)}%`;
  };

  return (
      <div className="d-flex flex-column pt-3 flex-grow-1">
        {isFetching ? (
          <Spinner color="primary" />
        ) : null}
        <div className="d-flex justify-content-center align-items-end">
            <h3 className="mb-0 display-3">x{(roiData.roi).toFixed(2)}</h3>
            <div className={`ml-2 mb-2 ${roiData.trend >= 0 ? 'text-success' : 'text-danger'}`}>
              <span>{formatPercentage(Math.abs(roiData.trend))}</span>
            </div>
        </div>

        <div className="mt-5 mx-auto d-flex flex-column" style={{width: '240px'}}>
          {roiData.series.map((item, index) => (
            <div key={index} className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex flex-column">
                <div className="font-weight-bold mb-1">{item.sponsor_object}</div>
                <div>{item.brand}</div>
              </div>
              <div className="flex align-items-center text-right">
                <div className='mr-2'>
                  x{item.roi.toFixed(2)}
                </div>
                <small className={`${item.trend >= 0 ? 'text-success' : 'text-danger'}`}>
                  {formatPercentage(Math.abs(item.trend))}
                </small>
              </div>
            </div>
          ))}
        </div>
    </div>
  );
};

export default ROIWidget;