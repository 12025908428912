import { format } from 'date-fns';
import numeral from 'numeral';
import React, { useState } from 'react'
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import ApiService from '../services/ApiService';
import CardSpinner from './CardSpinner';
import Filter from './Filter';
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'
import { useQuery } from "@tanstack/react-query";
import SocialMediaPostWidget from './SocialMediaPostWidget';

const PerformingSocialMediaPosts = ({
  type = "BESTE",
  date,
  selectedCurrency,
  tenant,
}) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState({
    value: "impression",
    order: type === "SVAKESTE",
  });

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  const {
    data: posts,
    isLoading,
    isRefetching,
    isSuccess,
  } = useQuery({
    queryKey: [
      "postsPerformance",
      tenant.id,
      `${sorting.order ? "-" : ""}${sorting.value}`,
      startDate,
      endDate,
    ],
    queryFn: () =>
      ApiService.getPerformingSocialMediaPosts(since, until, sorting.value, sorting.order ? 'asc' : 'desc'),
    select: (res) => {
      const [facebook, instagram] = res.data;
      return facebook
        .concat(instagram)
        .sort((a, b) =>
          sorting.order
            ? a[sorting.value] - b[sorting.value]
            : b[sorting.value] - a[sorting.value]
        );
    },
    enabled,
    placeholderData: [{ data: [] }, { data: [] }],
    // Reduce stale time to 0 to always fetch fresh data
    staleTime: 0,
    // Cache data for 5 minutes
    cacheTime: 5 * 60 * 1000,
    // Refetch on window focus
    refetchOnWindowFocus: true,
    // Refetch on reconnect
    refetchOnReconnect: true,
    // Poll every 5 seconds to keep posts fresh
    refetchInterval: 5000,
    refetchIntervalInBackground: false
  });

  const [options, setOptions] = useState([
    {
      name: "Facebook",
      color: "var(--social-media-table-primary)",
      selected: true,
    },
    {
      name: "Instagram",
      color: "var(--social-media-table-secondary)",
      selected: true,
    },
  ]);

  const onSortClick = (value) => {
    if (sorting.value !== value) {
      setSorting({ value, order: sorting.order });
    }
  };

  const renderChevron = (value) => {
    if (sorting.value === value) {
      return (
        <span
          className={`mdi mdi-chevron-${sorting.order ? "up" : "down"}`}
        ></span>
      );
    }
    return <></>;
  };

  const postsFiltered = posts?.filter((post) =>
    options.some(
      (option) =>
        option.name.toLowerCase() === post.media && option.selected === true
    )
  )?.slice(0, 10) ?? [];

  return (
    <Card>
      <CardBody>
        <h2 className="card-title mb-4">
          {type === "BESTE"
            ? t("10 beste poster sosiale medier")
            : t("10 svakeste poster sosiale medier")}
        </h2>
        {isSuccess && (
          <Row className="align-items-center">
            <Col lg={2} xs={6} className="mb-4">
              <SocialMediaPostWidget post={postsFiltered?.[0]} aspectRatio='4 / 5' isLoadingPost={isLoading} />
            </Col>
            <Col lg={2} xs={6} className="mb-4">
              <SocialMediaPostWidget post={postsFiltered?.[1]} aspectRatio='4 / 5' isLoadingPost={isLoading} />
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Table borderless responsive size="sm">
                <thead>
                  <tr>
                    <th>{t("Post")}</th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => onSortClick("timestamp")}
                    >
                      {t("Dato")}
                      {renderChevron("timestamp")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => onSortClick("impression")}
                    >
                      {t("Rekkevidde")}
                      {renderChevron("impression")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => onSortClick("engagement")}
                    >
                      {t("Engasjement")}
                      {renderChevron("engagement")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => onSortClick("impression")}
                    >
                      {t("Synlighetsverdi")} ({selectedCurrency.currency})
                      {renderChevron("imperssion")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => onSortClick("engagement")}
                    >
                      {t("Engasjementsverdi")} ({selectedCurrency.currency})
                      {renderChevron("engagement")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {postsFiltered
                    .map((post) => (
                      <tr key={`post-${post.id}`}>
                        <td>
                          <a
                            //href={test ? null : post.permalink}

                            href={post.permalink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color:
                                post.media === "facebook"
                                  ? "var(--social-media-table-primary)"
                                  : "var(--social-media-table-secondary)",
                            }}
                          >
                            {post?.message?.substring?.(0, 120) ??
                              "Post uten melding"}
                          </a>
                        </td>
                        <td>{format(new Date(post.timestamp), "dd/MM/yyyy")}</td>
                        <td>{numeral(post.impression).format("0.0a")}</td>
                        <td>{numeral(post.engagement).format("0.0a")}</td>
                        <td>
                          {numeral(post.value * selectedCurrency.value).format(
                            "0.0a"
                          )}
                        </td>
                        <td>
                          {numeral(
                            post.engagement * 4.7 * selectedCurrency.value
                          ).format("0.0a")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}

        <Row>
          <Col className="d-flex justify-content-center">
            <Filter options={options} setOptions={setOptions} />
          </Col>
        </Row>
        <CardSpinner loading={isLoading || isRefetching} />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  user: state.auth.user,
  selectedCurrency: state.currency.selectedCurrency,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(PerformingSocialMediaPosts);