import { Spinner, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Card, CardBody } from "reactstrap";
import { useGetSponsorObjects, useSponsorContracts, useTenantBrands } from "../query/hooks";
import { useState } from "react";
import { useCreateSponsorContract, useUpdateSponsorContract, useDeleteSponsorContract } from "../query/mutations";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const SponsorContracts = () => {
  const [modal, setModal] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [formData, setFormData] = useState({
    tenant_logograb_brands_id: "",
    category: "",
    start_date: "",
    end_date: "",
    yearly_value: "",
  });

  const { t } = useTranslation();

  const { data: tenantBrands = [], isFetching: isFetchingBrands } = useTenantBrands();
  const { data: contracts = [], isFetching: isFetchingSponsorContracts } = useSponsorContracts();
  const { data: sponsorObjects, isFetching: isFetchingSponsorObjects} = useGetSponsorObjects();

  const createMutation = useCreateSponsorContract();
  const updateMutation = useUpdateSponsorContract();
  const deleteMutation = useDeleteSponsorContract();
  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      setSelectedContract(null);
      setFormData({
        tenant_logograb_brands_id: "",
        sponsor_object_id: "",
        category: "",
        start_date: "",
        end_date: "",
        yearly_value: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedContract) {
      updateMutation.mutate({ id: selectedContract.id, data: formData }, {
        onSuccess: () => toggle()
      });
    } else {
      createMutation.mutate(formData, {
        onSuccess: () => toggle()
      });
    }
  };

  const handleEdit = (contract) => {
    setSelectedContract(contract);
    setFormData({
      tenant_logograb_brands_id: contract.tenant_logograb_brands_id.toString(),
      sponsor_object_id: contract.sponsor_object_id.toString(),
      category: contract.category,
      start_date: contract.start_date,
      end_date: contract.end_date,
      yearly_value: contract.yearly_value,
    });
    setModal(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this contract?")) {
      deleteMutation.mutate(id);
    }
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>{t("Platforms")}</h2>
        <Button color="primary" onClick={toggle}>
          {t('New Platform')}
        </Button>
      </div>
      <Card className="mini-stat bg-card text-white">
        <CardBody>
        {(isFetchingSponsorContracts || isFetchingBrands || isFetchingSponsorObjects) && (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        )}
          <Table hover responsive>
            <thead>
              <tr>
                <th>{t("Sponsor Object")}</th>
                <th>{t("Brand")}</th>
                <th>{t("Contract Duration")}</th>
                <th>{t("Yearly Contract Value")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {contracts.map((contract) => (
                <tr key={contract.id}>
                  <td>{contract.sponsor_object.name}</td>
                  <td>
                    <img src={contract.brand?.icon_url} alt="" className="object-contain mr-2" style={{height: '30px'}} />
                    {contract.brand?.name || '-'}
                  </td>
                  <td>{format(new Date(contract.start_date), 'dd.MM yyyy')} - {format(new Date(contract.end_date), 'dd.MM yyyy')}</td>
                  <td>{numeral(contract.yearly_value).format('0,0')}</td>
                  <td className="text-right">
                    <Button
                      color="primary"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleEdit(contract)}
                    >
                      {t('Edit')}
                    </Button>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDelete(contract.id)}
                    >
                      {t('Delete')}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {selectedContract ? t("Edit Platform") : t("New Platform")}
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="sponsor_object_id">{t("Sponsor object")}</Label>
              <Input
                type="select"
                name="sponsor_object_id"
                id="sponsor_object_id"
                value={formData.sponsor_object_id}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    sponsor_object_id: e.target.value,
                  })
                }
                required
              >
                <option value="">{t("Select a sponsor object")}</option>
                {sponsorObjects.map((sponsorObject) => (
                  <option key={sponsorObject.id} value={sponsorObject.id}>
                    {sponsorObject.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="tenant_logograb_brands_id">{t("Brand")}</Label>
              <Input
                type="select"
                name="tenant_logograb_brands_id"
                id="tenant_logograb_brands_id"
                value={formData.tenant_logograb_brands_id}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tenant_logograb_brands_id: e.target.value,
                  })
                }
                required
              >
                <option value="">{t("Select a brand")}</option>
                {tenantBrands.map((brand) => (
                  <option key={brand.id} value={brand.id}>
                    {brand.brand.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="category">{t("Category")}</Label>
              <Input
                type="select"
                name="category"
                id="category"
                value={formData.category}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    category: e.target.value,
                  })
                }
                required
              >
                <option value="">{t("Select a category")}</option>
                <option value="sport">{t("Sport")}</option>
                <option value="athlete">{t("Athlete")}</option>
                <option value="culture">{t("Culture")}</option>
                <option value="other">{t("Other")}</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="start_date">{t("Contract Start")}</Label>
              <Input
                type="date"
                name="start_date"
                id="start_date"
                value={formData.start_date}
                onChange={(e) =>
                  setFormData({ ...formData, start_date: e.target.value })
                }
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="end_date">{t("Contract End")}</Label>
              <Input
                type="date"
                name="end_date"
                id="end_date"
                placeholder="dd-mm-yyyy"
                value={formData.end_date}
                onChange={(e) =>
                  setFormData({ ...formData, end_date: e.target.value })
                }
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="value">{t("Yearly Value")}</Label>
              <Input
                type="text"
                name="value"
                id="value"
                placeholder="Yearly Value"
                value={numeral(formData.yearly_value || 0).format('0,0')}
                onChange={(e) => {
                  const input = e.target;
                  const position = input.selectionStart;
                  const rawValue = e.target.value.replace(/[^0-9]/g, '');
                  if (isNaN(rawValue) || rawValue > Number.MAX_SAFE_INTEGER) {
                    return;
                  }

                  setFormData({ ...formData, yearly_value: rawValue ? parseInt(rawValue) : 0 });

                  // Restore cursor position after React rerender
                  requestAnimationFrame(() => {
                    input.selectionStart = position;
                    input.selectionEnd = position;
                  });
                }}
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              {t("Cancel")}
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={createMutation.isLoading || updateMutation.isLoading}
            >
              {createMutation.isLoading || updateMutation.isLoading ? (
                <Spinner size="sm" />
              ) : selectedContract ? (
                t("Update")
              ) : (
                t("Create")
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default SponsorContracts;
