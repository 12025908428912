import React, { useMemo } from 'react';
import { Card, CardBody, CardTitle, Spinner } from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from "react-i18next";
import { useThemeContext } from "../../contexts/ThemeContext";
import useSegmentMetrics from "../../query/hooks/useSegmentMetrics";

const BarGraphGroup = ({ statement, questionGroup, optionCodes }) => {
  const { isDark } = useThemeContext();
  const { t } = useTranslation();
  const { data, isLoading } = useSegmentMetrics({ 
    groups: [questionGroup],
    optionCodes 
  });

  const series = data?.data?.series || [];
  const barColors = ['#A6CEE3', '#B2DF8A', '#FB9A99', '#FDBF6F'];

  const barOptions = useMemo(() => ({
    chart: {
      type: 'bar',
      backgroundColor: isDark ? '#2B283B' : '#fff',
      height: 50,
      spacing: [0, 0, 0, 0],
      gridLineWidth: 0,
      contextMenu: 'none'  // Disable context menu
    },
    navigation: {
      buttonOptions: {
        enabled: false  // Disable all navigation buttons including hamburger menu
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: [''],
      labels: {
        enabled: false
      },
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
      minorGridLineWidth: 0,
      minorTickWidth: 0
    },
     yAxis: {
      min: 0,
      max: 100,
      title: {
        text: ''
      },
      labels: {
        enabled: false
      },
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
      minorGridLineWidth: 0,
      minorTickWidth: 0
    },
    plotOptions: {
      bar: {
        borderWidth: 0,
        groupPadding: 0,
        pointPadding: 0,
        shadow: false,
        gridLineWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: isDark ? '#fff' : '#000'
          }
        }
      },
      series: {
        enableMouseTracking: false,
        states: {
          hover: {
            enabled: false
          }
        },
        gridLineWidth: 0
      }
    },
    grid: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  }), [isDark]);

  const pieData = useMemo(() => series.map((item, index) => ({
    name: item.name,
    y: item.data[0],
    color: barColors[index % barColors.length]
  })), [series, barColors]);

  const pieOptions = useMemo(() => ({
    chart: {
      type: 'pie',
      backgroundColor: isDark ? '#2B283B' : '#fff',
      spacing: [0, 0, 0, 0],
      contextMenu: 'none',  // Disable context menu
    },
    navigation: {
      buttonOptions: {
        enabled: false  // Disable all navigation buttons including hamburger menu
      }
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true,
        colors: barColors
      }
    },
    series: [{
      name: 'Recognition',
      data: pieData,
      colorByPoint: false
    }],
    credits: {
      enabled: false
    }
  }), [isDark, pieData, barColors]);

  if (isLoading) {
    return (
      <Card>
        <CardBody className="text-center">
          <Spinner color="primary" />
        </CardBody>
      </Card>
    );
  }

  if (!series.length) {
    return (
      <Card>
        <CardBody>
          <div className="text-center">No data available</div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">{statement}</CardTitle>
        <div className="d-flex">
          <div className="flex-grow-1 pe-4" style={{ width: '70%' }}>
            {series.map((item, index) => (
              <div key={item.name} className="mb-3">
                <div className="mb-2 fw-bold">{item.name}</div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    ...barOptions,
                    series: [{
                      data: [item.data[0]],
                      color: barColors[index % barColors.length],
                      showInLegend: false,
                      name: ''
                    }]
                  }}
                />
              </div>
            ))}
          </div>
          <div style={{ width: '30%' }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={pieOptions}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default BarGraphGroup;
