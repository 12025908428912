import React from "react"
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'
import { Spinner } from 'reactstrap'
import { connect } from "react-redux"

const BarGraph = ({
  useGraphQuery,
  queryParams,
  defaultActiveTab = 1,
}) => {
  const { data, isLoading, isRefetching, isSuccess } = useGraphQuery(
    ...queryParams
  );
  const chartOptions = (graph) => {
    return {
      credits: { enabled: false },
      chart: {
        type: "column",
        backgroundColor: 'transparent',  // <-- Add background color
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: graph.data.yTitle,
        },
        labels: {
          style: {
            color: 'var(--text-primary)'
         }
        },
      },
      xAxis: {
        categories: graph.data.labels,
        labels: {
          style: {
            color: 'var(--text-primary)'
         }
        },
        title: {
          text: graph.data.xTitle,
        },
        scrollbar: {
          enabled: true,
        },
      },
      series: graph.data.series,
      plotOptions: {
        column: {
          borderColor: null
        },
      },
    };
  };


  return (
    <>
      {(isLoading || isRefetching) && (
        <Spinner
          color="primary"
          size="sm"
          style={{ position: "absolute", right: 20 }}
        />
      )}
      {isSuccess && (
        <>
          {
            data && (
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(data)}
              />
            )
          }
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(BarGraph);
