import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { SPONSOR_CONTRACT_CATEGORY_DIVDED } from "../keys";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

const useSponsorContractCategoryDivded = (sponsorObjectId) => {
    const { startDate, endDate } = useSelector((state) => state.date);
    const enabled = !!(startDate && endDate);
    let since;
    let until;
    if (enabled) {
        since = format(startDate, "yyyy-MM-dd");
        until = format(endDate, "yyyy-MM-dd");
    }
    const tenant = useSelector((state) => state.auth.tenant);
    return useQuery({
        queryKey: [SPONSOR_CONTRACT_CATEGORY_DIVDED, tenant.id, startDate, endDate, sponsorObjectId],
        queryFn: () => ApiService.getSponsorContractCategoryDivded(since, until, sponsorObjectId),
        select: (data) => data.data,
        placeholderData: { data: [] },
    });
};

export default useSponsorContractCategoryDivded;