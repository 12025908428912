import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiService from "../../services/ApiService";
import { SPONSOR_CONTRACTS, SPONSOR_ROI, SPONSOR_CONTRACT_CATEGORY_DIVDED } from "../keys";

const useDeleteSponsorContract = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => ApiService.deleteSponsorContract(id),
    onSuccess: () => {
      queryClient.invalidateQueries([SPONSOR_ROI]);
      queryClient.invalidateQueries([SPONSOR_CONTRACT_CATEGORY_DIVDED]);
      queryClient.invalidateQueries([SPONSOR_CONTRACTS]);
    },
  });
};

export default useDeleteSponsorContract;
