import { format } from 'date-fns';
import React from 'react'
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import ApiService from "../services/ApiService";
import Highcharts from "highcharts";
import { connect } from "react-redux";
import HCMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

HCMore(Highcharts);

const BestSocialMediaPostBubbles = ({ date, tenant }) => {
  const { t } = useTranslation();
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }

  const { data, isLoading, isRefetching, isSuccess } = useQuery({
    queryKey: [
      "socialmediaPerformance",
      tenant.id,
      date.startDate,
      date.endDate,
    ],
    queryFn: () =>
      ApiService.getPerformingSocialMediaPosts(since, until, 'impression', 'desc'),
    select: (res) => {
      const [facebook, instagram] = res.data;
      return [facebook, instagram];
    },
    enabled,
    placeholderData: [{ data: [] }, { data: [] }],
  });
  let facebookPosts = [];
  let instagramPosts = [];
  if (data) {
    [facebookPosts, instagramPosts] = data;
  }
  const chartOptions = () => {
    const reach = t("Rekkevidde");
    const engagement = t("Engasjement");
    const value = t("Verdi");

    return {
      exporting: {
        enabled: true,
      },
      credits: { enabled: false },
      chart: {
        type: "bubble",
        plotBorderWidth: 1,
        backgroundColor: 'transparent',
      },
      title: {
        text: "",
      },
      xAxis: {
        labels: {
          style: {
            color: 'var(--text-primary)'
         }
        },
        title: {
          text: reach,
          style: {
            color: 'var(--text-primary)'
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            color: 'var(--text-primary)'
         }
        },
        title: {
          text: engagement,
          style: {
            color: 'var(--text-primary)'
          }
        },
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<table>",
        pointFormat:
          "<tr><th>{point.message}</th></tr>" +
          "<tr><th>" +
          reach +
          ": {point.x}</th</tr>" +
          "<tr><th>" +
          engagement +
          ": {point.y}</th></tr>" +
          "<tr><th>" +
          value +
          ": {point.z}</th></tr>",
        footerFormat: "</table>",
        followPointer: true,
      },
      series: [
        {
          name: "Facebook",
          data: facebookPosts.map((post) => ({
            x: post.impression,
            y: post.engagement,
            z: post.value,
            message: post.message,
          })),
          marker: {
            fillColor: {
              radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
              stops: [
                [0, "rgba(255,255,255,0.5)"],
                [1, Highcharts.color("#107CA2").setOpacity(0.5).get("rgba")],
              ],
            },
          },
        },
        {
          name: "Instagram",
          data: instagramPosts.map((post) => ({
            x: post.impression,
            y: post.engagement,
            z: post.value,
            message: post.message,
          })),
          marker: {
            fillColor: {
              radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
              stops: [
                [0, "rgba(255,255,255,0.5)"],
                [1, Highcharts.color("#EBBC40").setOpacity(0.5).get("rgba")],
              ],
            },
          },
        },
      ],
    };
  };

  return (
    <Card>
      <CardBody>
        <h2 className="card-title mb-4">
          {t("10 Beste poster sosiale medier")}
        </h2>
        {(isLoading || isRefetching) && <Spinner color="primary" size="sm" />}
        {isSuccess && (
          <Row>
            <Col md={12} lg={12}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions()}
              />
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(BestSocialMediaPostBubbles);
