import HighchartsReact from "highcharts-react-official"
import Highcharts from 'highcharts'
import { Spinner } from "reactstrap";

const PieChart = ({ useQuery, params }) => {
  const { data, isFetching } = useQuery(
    ...params
  );

  const chartOptions = () => {
    return {
      credits: { enabled: false },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true,
          outline: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: '',
          colorByPoint: true,
          borderColor: 'transparent',
          data,
        }
      ]
    }
  }
  return (
    <>
      {isFetching && <Spinner />}
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions()}
      />
    </>
  );
}

export default PieChart;
