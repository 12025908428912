import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { DATA_SOURCES } from "../keys";

const useDataSources = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [DATA_SOURCES, tenant.id],
    queryFn: () => ApiService.getSocialMediaState(),
    select: (data) => data.data,
    placeholderData: [{data: []}],
    staleTime: 0,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: (data) => {
      // Check if any access token has a statusHint
      const hasStatusHint = data?.some(social => 
        social.accessTokens?.some(token => token.statusHint)
      );
      // Only poll if there's a statusHint present
      return hasStatusHint ? 2000 : 5000;
    },
    refetchIntervalInBackground: true,
  });
};

export default useDataSources;
