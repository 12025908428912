import React from "react";
import Highcharts from "highcharts";
import { Spinner } from "reactstrap";
import HighchartsReact from "highcharts-react-official";
import ApiService from "../services/ApiService";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";

const TicketPotential = ({ tenant }) => {
  const { data, isLoading, isRefetching, isSuccess } = useQuery({
    queryKey: ["potentialTickets", tenant.id],
    queryFn: ApiService.getPotentialTickets,
    select: (data) => data.data,
    placeholderData: {
      data: [],
    },
  });

  const chartOptions = () => {
    return {
      credits: { enabled: false },
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      yAxis: [
        {
          title: {
            text: data.yTitle,
          },
          labels: {
            style: {
              color: 'var(--text-primary)'
           }
          }
        },
        {
          min: 0,
          max: 100,
          tickInterval: 20,
          title: {
            text: "",
          },
          labels: {
            format: "{value}%",
          },
          opposite: true,
          plotBands: [
            {
              color: "#96686880",
              from: 60,
              to: 80,
              innerRadius: "50%",
            },
          ],
        },
      ],
      xAxis: {
        categories: data.labels,
        labels: {
          rotation: -45,
          style: {
            color: 'var(--text-primary)'
         }
        },
        title: {
          text: data.xTitle,
        },
      },
      series: data.series,
      plotOptions: {
        column: {
          stacking: "normal",
          borderColor: null,

        },
      },
    };
  };

  if (isLoading) {
    return <></>;
  }
  return (
    <>
      {(isLoading || isRefetching) && <Spinner color="primary" />}
      {isSuccess && (
        <HighchartsReact highcharts={Highcharts} options={chartOptions()} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(TicketPotential);
