import React from 'react';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import DemographicPieChart from './charts/DemographicPieChart';
import BarGraph from "../BarGraph";
import BarGraphGroup from '../BarGraphGroup/BarGraphGroup';
import { useTranslation } from "react-i18next";
import { useThemeContext } from "../../contexts/ThemeContext";
import useSegmentMetrics from "../../query/hooks/useSegmentMetrics";
import useSegmentMetricsPie from "../../query/hooks/useSegmentMetricsPie";
import PieChart from "../../components/PieChart";

const AudienceInsights = () => {
  const { isDark } = useThemeContext();
  const {t} = useTranslation();

  return (
    <div className="audience-insights">
      <h2 className="mb-4">{t('Audience Insights')}</h2>
      <Row>
        <Col md={6} lg={6} className="mb-4">
          <BarGraphGroup 
            statement={t('Athletes recognised by the audience')}
            questionGroup={2312}
            optionCodes={['c2', 'c3']}
          />
        </Col>
        <Col md={6} lg={6} className="mb-4">
          <BarGraphGroup 
            statement={t('Associations recognised by the audience')}
            questionGroup={2313}
            optionCodes={['c2', 'c3']}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={6} className="mb-4">
          <BarGraphGroup 
            statement={t('Culture recognised by the audience')}
            questionGroup={2314}
            optionCodes={['c2', 'c3']}
          />
        </Col>
        <Col md={6} lg={6} className="mb-4">
          <Card>
            <CardBody>
              <CardTitle tag="h5">{t('Gender')}</CardTitle>
              <DemographicPieChart 
                questionCode="gender" 
                title="Gender" 
                isDarkMode={isDark} 
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={6} className="mb-4">
          <Card>
            <CardBody>
              <CardTitle tag="h5">{t('Age')}</CardTitle>
              <DemographicPieChart 
                questionCode="age_group"
                title="Age" 
                isDarkMode={isDark} 
              />
            </CardBody>
          </Card>
        </Col>
        {/* Add more chart components here */}
      </Row>
    </div>
  );
};

export default AudienceInsights;
