import React from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import ApiService from "../services/ApiService";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

const LeagueClubFollowers = ({ date, tenant }) => {
    const { startDate, endDate } = date;
    const enabled = !!(startDate && endDate);
    let since, until;
    if (enabled) {
        since = format(startDate, "yyyy-MM-dd");
        until = format(endDate, "yyyy-MM-dd");
    }

    const { data, isLoading, isRefreshing, isSuccess } = useQuery({
        queryKey: ["leagueClubFollowers", tenant.id, since, until],
        queryFn: () => ApiService.getLeagueClubFollowers(since, until),
        select: ({ data }) => data.map(item => ({ club: item.club, followers: item.followers })),
        enabled,
        placeholderData: [],
    });

    const getChartOptions = () => {
        const clubs = data.map(item => item.club);
        const followers = data.map(item => item.followers);
        const colors = ['#377c9f', '#a09ea8', '#ecbc32', '#972BA0', '#A01049'];

        return {
            credits: { enabled: false },
            chart: {
                type: "column",
                backgroundColor: 'transparent',
            },
            title: { text: "" },
            xAxis: {
                categories: clubs,
                labels: { rotation: -45,
                    style: {
                        color: 'var(--text-primary)'
                    }
                },
            },
            yAxis: {
                title: { 
                    text: "Followers",
                    style: {
                        color: 'var(--text-primary)'
                    }

                },
                labels: { rotation: -45,
                    style: {
                        color: 'var(--text-primary)'
                    }
                },
                min: 0,
            },
            series: [{
                data: followers,
                showInLegend: false,
            }],
            plotOptions: {
                series: {
                    colorByPoint: true,
                    colors: colors,
                }
            },
        };
    };

    return (
        <>
            {(isLoading || isRefreshing) && <Spinner color="primary" size="sm" style={{ position: "absolute", right: 20 }} />}
            {isSuccess && <HighchartsReact highcharts={Highcharts} options={getChartOptions()} />}
        </>
    );
}

const mapStateToProps = (state) => ({
    date: state.date,
    tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(LeagueClubFollowers);
