import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Row
} from 'reactstrap';
import FullscreenBtn from '../components/FullscreenBtn.js';
import { setUserTenant } from '../store/actions/authActions';
import SimpleBar from 'simplebar-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TenantDropdown = ({variant, theme = true, fullscreen = true}) => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => setMenu(!menu);
  
  const { tenants, tenant, user } = useSelector(state => ({
    tenants: state.auth.tenants,
    tenant: state.auth.tenant,
    user: state.auth.user,
  }));

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  }

  const allTenants = [
    ...tenants,
  ];

  const filteredTenants = allTenants.filter(tenant => 
    tenant.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //  Set focus to search input when dropdown is opened
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (menu) { // If the dropdown menu is open
      searchInputRef.current.focus(); // Focus the search input
    }
  }, [menu]); // This effect depends on the `menu` state

  //  Set a keyboard shortcut on the tenant drop down menu

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Ctrl + T is pressed, then toggle the dropdown menu
      if (event.ctrlKey && event.key === '/') {
        event.preventDefault(); // Prevent the default action to avoid conflicts
        toggleMenu(); // Toggle the dropdown menu
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means this effect runs only once on mount


  const dispatch = useDispatch();

  const toggle = () => setMenu(prevMenu => !prevMenu);

  const history = useHistory();

  const activateTenant = tenant => {
    dispatch(setUserTenant(tenant));
    history.push('/dashboard')
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission on enter
      activateTenant(filteredTenants[0]);
      toggleMenu();
      setSearchTerm('');
    }
  }

  const renderItem = (tenant, index) => (
    <DropdownItem
      tag="a"
      href="#"
      className="notify-item"
      key={index}
      onClick={e => {
        e.preventDefault();
        activateTenant(tenant);
      }}
    >
      <span className="align-middle">{tenant.name}</span>
    </DropdownItem>
  );

  if (tenants.length <= 1) return null;
  return (
    <>
      {variant !== 'athlete' &&
        <>
          {fullscreen && !isMobile && <FullscreenBtn />}
        </>
      }
      <Dropdown isOpen={menu} toggle={toggle}>
        <DropdownToggle className="btn header-item waves-effect noti-icon" tag="button">
          <div className="d-flex d-md-none">
            <i className="mdi mdi-home-account"></i>
          </div>
          <div className="d-none d-md-inline">
            {tenant.name}
            <span className="mdi mdi-chevron-down"></span>
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0 font-size-16">{t("Velg lag")}</h5>
              </Col>
            </Row>
            <Row className="align-items-center" style={{ marginTop: '10px'}}>
              <Col>
              <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="search-icon">
                  <FaSearch/>
                </InputGroupText>
              </InputGroupAddon>
              <input
                  ref={searchInputRef}
                  type="text"
                  placeholder={t("Søk")}
                  value={searchTerm}
                  onChange={handleSearch}
                  onKeyDown={handleKeyDown}
                  className="form-control input-placeholder"
                />
              </InputGroup>
              </Col>
            </Row>
          </div>
          <SimpleBar style={{ height: '230px' }}>
            {filteredTenants.map((tenant, index) => renderItem(tenant, index))}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default TenantDropdown;
