import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'
import { FaTiktok, FaFacebook, FaYoutube, FaTwitter, FaInstagram } from "react-icons/fa6";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Button,
  CardTitle,
  CardText
} from "reactstrap";
import Switch from "react-switch";
import { FaExternalLinkAlt } from "react-icons/fa";
import { format } from "date-fns";
import store from '../store'
import SocialService from "../services/SocialService";
import { useToggleDataSourcesMutation } from "../query/hooks";
import { Spinner } from 'reactstrap';

const providerToName = {
  facebook: "Facebook & Instagram",
  'facebook-business': "Facebook (Business)",
  x: "X",
  youtube: 'YouTube',
  tiktok: 'TikTok',
  google: 'Google',
}

const SocialState = ({ social, isLoading }) => {
  const {accessTokens, provider} = social;
  const socialMediaName = providerToName?.[provider] ?? provider;
  const {mutate: toggleDataSourceMutation} = useToggleDataSourcesMutation();

  const { t } = useTranslation();

  const getIcon = (type) => {
    switch (type) {
      case "facebook":
        return FaFacebook;
      case "instagram":
        return FaInstagram;
      case "tiktok":
        return FaTiktok;
      case "youtube":
        return FaYoutube;
      case "twitter":
        return FaTwitter;
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="social-state">
        <div className="social-loading">
          <Spinner color="primary" />
        </div>
      </div>
    );
  }

  const providerToSocial = () => {
    const {  user } = store.getState().auth

    if (typeof user.test !== 'undefined' && user.test === 1 && provider === 'facebook') {
      return SocialService.facebookLoginLink()
    }

    switch (provider) {
      case 'facebook':
      case 'instagram':
        return SocialService.facebookBusinessLoginLink();
      case 'facebook-business':
        return SocialService.facebookBusinessLoginLink();
      case 'twitter':
        return SocialService.twitterLoginLink();
      default:
        return '#';
    }
  }

  return (
    <div className="social-state">
      <Container>
        <Row className="align-items-center">
          <Col xs="6">
            <h5>{ socialMediaName }</h5>
          </Col>
          <Col xs="6" className="text-right">
            <FormGroup className="social-form-group">
              <a href={providerToSocial()}>
                <Button
                  className="btn ml-4 waves-effect waves-light"
                  label="Connect"
                  color="primary"
                >
                  {t('Connect')}
                </Button>
              </a>
            </FormGroup>
          </Col>
        </Row>
        
        
        {/* {Array.isArray(accessTokens) && accessTokens.length > 0 && accessTokens.map((accessToken) => (
          accessToken.accounts.every(token => token.provider !== "instagram") && (
            <Card className="mb-3">
              <CardBody>
                <CardTitle style={{marginBottom: '20px'}}>{t('Link your Instagram account')}</CardTitle>
                <CardText>
                  <p>
                    <Trans i18nKey="linkedInstagramHelp">
                      {t('linkedInstagramHelpIntro')} <a href="https://www.facebook.com/business/help/connect-instagram-to-page" target="_blank" rel="noreferrer" className="text-dark">{t('linkedInstagramHelpLink1')}</a>{t('linkedInstagramHelpBetweenLinks')}<a href="https://help.instagram.com/138925576505882/" className="text-dark" target="_blank" rel="noreferrer">{t('linkedInstagramHelpLink2')}</a>
                    </Trans>.
                  </p>
                  </CardText>
              </CardBody>
            </Card>
        )))} */}
        
        
        {Array.isArray(accessTokens) && accessTokens.length > 0 && accessTokens.map((accessToken) => (
          Array.isArray(accessToken.accounts) && accessToken.accounts.length > 0 && (
            <Row className="mb-3" key={accessToken.id}>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle style={{marginBottom: '20px'}}>
                      {accessToken.statusHint ? (
                        <div className="d-flex align-items-center">
                            {accessToken.statusHint}
                            <div className="social-loading">
                              <Spinner color="primary" />
                            </div>
                        </div>
                      ) : (
                        t('Connected accounts')
                      )}
                    </CardTitle>
                    {accessToken.accounts.map((account, index) =>  {
                      const IconComponent = getIcon(account.provider) || null;
                      return (
                        <div key={index} className="social-account">
                          <Link 
                            className="social-link" 
                            to={{ pathname: account.link }} 
                            target="_blank"
                          >
                            {IconComponent && <IconComponent />} 
                            {account?.name?.length > 40 ? `${account.name.substring(0, 40)}...` : account?.name}
                            <FaExternalLinkAlt className="external-link" />
                          </Link>
                          <Switch
                            onColor="#02a499"
                            onChange={() => toggleDataSourceMutation({
                              provider: accessToken.provider,
                              accountProvider: account.provider,
                              accessTokenId: accessToken.id,
                              accountId: account.id,
                              newState: account.include ? 0 : 1
                            })}
                            checked={!!account?.include}
                          />
                        </div>
                      );
                    })}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )
        ))}
      </Container>
    </div>
  );
};

export default SocialState;
