import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import {SPONSOR_VALUE_DIVIDED_YEAR } from "../keys";
import { format } from "date-fns";

const useTenantProfileData = (sponsorObjectId) => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }

  return useQuery({
    queryKey: [SPONSOR_VALUE_DIVIDED_YEAR, tenant.id, sponsorObjectId, startDate, endDate],
    queryFn: () => ApiService.getSponsorDividedValueYear(sponsorObjectId, since, until),
    select: (data) => data.data,
    placeholderData: {
      data: {
        total: 0,
        trend: 0,
        series: [],
      }
    },
  });
};

export default useTenantProfileData;
