import React from "react";
import { connect } from "react-redux";

import BoxedMetric from "../components/BoxedMetric";
import PerformanceIndex from "../components/PerformanceIndex";
import PerformingSocialMediaPosts from "../components/PerformingSocialMediaPosts";
import PerformingSocialMediaMentions from "../components/PerformingSocialMediaMentions";
import PerformingSocialMediaTagged from "../components/PerformingSocialMediaTagged";
import numeral from "numeral";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const PerformancePerson = () => {
  const { t } = useTranslation();
  const primarySocialFn = (primary) => {
    return `${numeral(primary).format('0.0a')} ${t('Followers')}`;
  }

  const secondarySocialFn = (secondary) => {
    return `${t('Engagement Rate')} ${numeral(secondary).format('0%')}`;
  }

  return (
    <div className="container-fluid pt-5 p-0 value-person-view">
      <Row className="mb-4 px-4">
        <Col xs={6}>
          <BoxedMetric
            icon="instagram"
            metric="instagram-followers"
            subtitle={t("Followers")}
            titleFn={(primary, secondary) => "Instagram"}
            primaryFn={primarySocialFn}
            secondaryFn={secondarySocialFn}
            link="/performance/source?source=instagram"
            colour="red"
          />
        </Col>
        <Col xs={6}>
          <BoxedMetric
            icon="x"
            metric="x-followers"
            subtitle={t("Followers")}
            titleFn={(primary, secondary) => "X"}
            primaryFn={primarySocialFn}
            secondaryFn={secondarySocialFn}
            link="/performance/source?source=x"
            colour="yellow"
          />
        </Col>
      </Row>
      <Row className="mb-4 px-4">
        <Col xs={6}>
          <BoxedMetric
            icon="facebook"
            metric="facebook-followers"
            subtitle={t("Followers")}
            titleFn={(primary, secondary) => "Facebook"}
            primaryFn={primarySocialFn}
            secondaryFn={secondarySocialFn}
            link="/performance/source?source=facebook"
            colour="greyblue"
          />
        </Col>
        <Col xs={6}>
          <BoxedMetric
            icon="youtube"
            metric="youtube-followers"
            subtitle={t("Followers")}
            titleFn={(primary, secondary) => "Youtube"}
            primaryFn={primarySocialFn}
            secondaryFn={secondarySocialFn}
            link="/performance/source?source=youtube"
            colour="blue"
          />
        </Col>
      </Row>
      <div className="mb-4 px-4">
        <PerformanceIndex />
      </div>

      <div className="mb-4 px-4">
        <PerformingSocialMediaPosts />
      </div>

      <div className="mb-4 px-4">
        <PerformingSocialMediaMentions />
      </div>

      <div className="mb-4 px-4">
        <PerformingSocialMediaTagged />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
	tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(PerformancePerson);
