import React from "react";
import { Row, Card, CardBody, Col } from "reactstrap";
import { connect } from "react-redux";

import BoxedMetric from "../components/BoxedMetric";

import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighMaps from "highcharts/modules/map";

import DemographicsChart from "../components/DemographicsChart.js";
import AthleteGeography from "../components/AthleteGeography.jsx";
import { useTranslation } from "react-i18next";
import InfoTooltip from "../components/InfoTooltip.js";
import numeral from "numeral";

HighchartsMore(Highcharts);
HighMaps(Highcharts);

const AthleteAudience = ({ selectedCurrency, tenant }) => {
  const {t} = useTranslation();

  return (
    <div className="container-fluid pt-5 p-0">
      <Row className="mb-4 px-4">
        <Col xs={6}>
          <BoxedMetric
            titleFn={() => t('Followers')}
            metric="total-followers"
            subtitle="followers"
            colour="violet"
            primaryFn={primary => `${numeral(primary).format('0.0a')} ${t('Followers')}`}
          />
        </Col>
        <Col xs={6}>
          <BoxedMetric
            titleFn={() => t('Growth')}
            metric="total-followers-growth"
            colour="blue"
            primaryFn={primary => `${primary} ${t('last 3 months')}`}
          />
        </Col>
      </Row>
      <Row className="px-4 mb-4">
        <Col md={12}>
          <Card className="mini-stat text-white">
            <CardBody>
              <h4 className="card-title">
                {t("Alder og kjønn")}
                <InfoTooltip
                  tooltip={t(
                    "Viser demografisk nedbrytning av følgere i forhold til alder/livsfase og kjønn."
                  )}
                />
              </h4>
              <DemographicsChart />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="px-4 mb-4">
        <Col md={12}>
          <Card className="mini-stattext-white">
            <CardBody>
              <h4 className="card-title">
                {t('Geografi')}
                <InfoTooltip tooltip={t('Viser kommunene der følgere bor. Dette er ikke komplett data, mange brukere har ikke lokasjonsdata tilgjengelig.')} />
              </h4>
              <AthleteGeography showGlobalMap={true} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(AthleteAudience);
