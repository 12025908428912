import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import ApiService from "../services/ApiService";
import { toast } from "react-toastify";
import classNames from "classnames";
import SocialState from "../components/SocialState";
import { useDataSources } from "../query/hooks";
import { useTranslation } from "react-i18next";
import AddSponsors from "../components/AddSponsors";
import Switch from "react-switch";

const AthleteSettings = ({tenant}) => {
  const { tab } = useParams();
  const {t} = useTranslation();
  const {data: socialState, isLoading: isDataSourcesLoading} = useDataSources();
  const [settings, setSettings] = useState({
    name: "",
    profilePictureUrl: "",
    tagLine: "",
    testMode: false,
  });

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(tab || 'profile');

  const isOnboardingCompleted = tenant.onboarding_completed === 1;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const anchor = window.location.hash;
      console.log('anchor', anchor)

      if (anchor === "#socials") {
        setActiveTab("socials");
      }
    }
  }, [])

  useEffect(() => {
    console.log('tenant', tenant)
    if (isOnboardingCompleted === false) {
      setActiveTab('socials');
    }
  }, [isOnboardingCompleted, tenant]);

  useEffect(() => {
    setInitialLoading(true);
    ApiService.getMetaProfile()
      .then((userData) => {
        setSettings({
          name: userData.data.name || "",
          email: userData.data.email || "",
          profilePictureUrl: userData.data.imageUrl || "",
          tagLine: userData.data.shortDescription || "",
          testMode: userData.data.test || false,
        });
      })
      .catch((error) => {
        console.error("Failed to fetch profile data.");
      })
      .finally(() => {
        setInitialLoading(false);
      });
  }, []); // Empty dependency array means this effect runs once on mount

  const handleProfileChange = (e) => {
    const { name, value, checked, type } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: type === "checkbox" ? checked : value,
    }));
    console.log('settings:', settings)
    console.log('name:', name)
    console.log('value:', value)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    ApiService.setProfileData({
      name: settings.name,
      email: settings.email,
      profilePictureUrl: settings.profilePictureUrl,
      tagLine: settings.tagLine,
      testMode: settings.testMode,
    })
      .then((response) => {
        console.log("Profile data saved successfully:", response);
        toast.success("Profile updated successfully");
        // Handle success, such as showing a success message or redirecting
      })
      .catch((error) => {
        console.error("Failed to save profile data:", error);
        // Handle error, such as showing an error message
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  if (initialLoading) {
    return (
      <Container className="pt-4">
        <Row>
          <Col md={{ size: 6, offset: 3 }} className="text-center">
            <Spinner color="primary" /> {/* Display a loading spinner */}
            <p>Loading...</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Nav tabs className="nav-tabs-custom pt-4 mb-4">
        <NavItem>
          <NavLink
            href="#"
            disabled={!isOnboardingCompleted}
            className={classNames({ active: activeTab === "profile" })}
            onClick={() => {
              toggleTab("profile");
            }}
          >
            {t('Profile')}
          </NavLink>
        </NavItem>
        <NavItem
          onClick={() => {
            toggleTab("sponsors");
          }}
        >
          <NavLink
            href="#"
            className={classNames({ active: activeTab === "sponsors" })}
          >
            {t('Sponsors')}
          </NavLink>
        </NavItem>
        <NavItem
          onClick={() => {
            toggleTab("socials");
          }}
        >
          <NavLink
            href="#"
            className={classNames({ active: activeTab === "socials" })}
          >
            {t('Socials')}
          </NavLink>
        </NavItem>
      </Nav>

      {activeTab === "profile" && (
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">{t('Name')}</Label>
                <Input
                  className="form-control"
                  id="name"
                  name="name"
                  value={settings.name}
                  onChange={handleProfileChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">{t('Email')}</Label>
                <Input
                  id="name"
                  name="email"
                  value={settings.email}
                  onChange={handleProfileChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="profilePictureUrl">{t('Profile Picture URL')}</Label>
                <Input
                  id="profilePictureUrl"
                  name="profilePictureUrl"
                  value={settings.profilePictureUrl}
                  onChange={handleProfileChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tagLine">{t('Tag Line')}</Label>
                <Input
                  id="tagLine"
                  name="tagLine"
                  value={settings.tagLine}
                  onChange={handleProfileChange}
                />
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type="switch"
                  id="testMode"
                  name="testMode"
                  label={t('Test Mode')}
                  checked={settings.testMode}
                  onChange={handleProfileChange}
                />
              </FormGroup>
              <Button type="submit" color="primary" disabled={loading}>
                {loading ? t('Saving...') : t('Save Changes')}
              </Button>
            </form>
          </Col>
        </Row>
      )}

      {activeTab === "sponsors" && (
        <Row>
          <AddSponsors />
        </Row>
      )}

      {activeTab === "socials" && (
        isDataSourcesLoading ? (
          <Row>
            <Col md={{ size: 6, offset: 3 }} className="text-center">
              <Spinner color="primary" />
              <p>Loading...</p>
            </Col>
          </Row>
          ) : (
            <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <ul>
              {Array.isArray(socialState) ? socialState.map((social) => (
                <SocialState
                  key={social.provider}
                  social={social}
                />
              )) : (
                <li>{t('No social accounts connected')}</li>
              )}
            </ul>
            </Col>
          </Row>
          )
        )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
	tenant: state.auth.tenant,
});


export default connect(mapStateToProps)(AthleteSettings);
