import React, { useState, useEffect, useCallback } from 'react';
import { Chrono } from 'react-chrono';
import { toast } from 'react-toastify';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import ApiService from '../services/ApiService';
import usePersonSocialTimeline from '../query/hooks/usePersonSocialTimeline';
import { Spinner } from 'reactstrap';

const exampleComments = [
   { name: "John Doe", text: "Great post! Love seeing this kind of content 👏" },
  // { name: "Sarah Smith", text: "Amazing performance! Keep it up 🔥" },
  // { name: "Mike Johnson", text: "This is inspiring! 🙌" },
  // { name: "Emma Wilson", text: "Fantastic work! Can't wait to see more 🌟" },
  // { name: "Alex Brown", text: "You're crushing it! 💪" },
  // { name: "Lisa Chen", text: "This made my day! ❤️" },
  // { name: "David Kim", text: "Absolutely brilliant! 🎯" },
  // { name: "Rachel Green", text: "Such amazing energy! ✨" },
  // { name: "Tom Parker", text: "Keep pushing boundaries! 🚀" },
  // { name: "Maria Garcia", text: "You're an inspiration! 🌈" }
];

const SocialTimeline = () => {
  const {data: items, isLoading: isLoadingTimeline} = usePersonSocialTimeline();
  const [replies, setReplies] = useState({});
  const [comments, setComments] = useState([]);

  const commentsEnabled = useFeatureFlagEnabled('social-timeline-commenting');

  const getRandomComments = useCallback(() => {
    const shuffled = [...exampleComments].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, Math.floor(Math.random() * 4) + 2);
  }, []);

  useEffect(() => {
    // Initialize comments when items change
    if (commentsEnabled) {
      const initialComments = items.map(item => {
        return getRandomComments().map(comment => ({
          ...comment,
          postIndex: item.id || Math.random().toString(),
          timestamp: new Date()
        }));
      }).flat();
      setComments(initialComments);
    }
  }, [items, getRandomComments, commentsEnabled]);

  useEffect(() => {
    if (commentsEnabled) {
      const handleReplyClick = async (e) => {
        const isReplyLink = e.target.textContent.includes('💬 Reply to comment');
        if (isReplyLink) {
          e.preventDefault();
          const parentDiv = e.target.closest('div');
          const commentText = parentDiv.closest('div');
          const parentComment = comments.find(c => c.text === commentText);

          const reply = prompt('Enter your reply:');
          if (reply) {
            try {
              const topPostId = items[0]?.id || '0';

              // Send POST request to the API
              const response = await ApiService.postComment({
                post_id: topPostId,
                comment: reply,
                parent_comment_id: parentComment?.id,
                is_reply: true
              });

              if (response.status === 200) {
                const newComment = {
                  name: 'You',
                  text: reply,
                  timestamp: new Date(),
                  isReply: true,
                  postIndex: topPostId,
                  id: response.data.id // Store the comment ID from the response
                };

                setComments(prevComments => [...prevComments, newComment]);
                toast.success('Comment successfully posted.');
              } else {
                toast.error('Failed to post comment. Please try again.');
              }
            } catch (error) {
              toast.error('Failed to post comment. Please try again.');
            }
          }
        }
      };

      document.addEventListener('click', handleReplyClick);
      return () => document.removeEventListener('click', handleReplyClick);
    }
  }, [comments, items, commentsEnabled]);

  if (!items || items.length === 0) {
    return null;
  }

  const chronoItems = items.map((item, index) => {
    let mediaType = 'IMAGE';
    if (item.media === 'facebook') {
      mediaType = 'IMAGE';
    } else if (item.media_type === 'VIDEO') {
      mediaType = 'VIDEO';
    } else if (item.media_type === 'CAROUSEL_ALBUM') {
      mediaType = 'IMAGE';
    } else if (item.media_type === 'IMAGE') {
      mediaType = 'IMAGE';
    }
    // styling class is stripped by xss
    const stats = `
      <div class="timeline-stats">
        <span>👥 ${item.engagement?.toLocaleString() || 0}</span>
        <span>👁️ ${item.impression?.toLocaleString() || 0}</span>
        <span>💰 ${item.value?.toLocaleString()} NOK</span>
      </div>
    `;

    let testComments = [
      {
        title: "Nested Item 1",
        cardTitle: "Nested Event 1",
        cardSubtitle: "This is a nested event",
        cardDetailedText: "This is the detailed text for the nested event 1",
        media: {
          type: "IMAGE",
          source: {
            url: "https://picsum.photos/800/400",
          },
        },
      },
      {
        title: "Nested Item 2",
        cardTitle: "Nested Event 2",
        cardSubtitle: "This is another nested event",
        cardDetailedText: "This is the detailed text for the nested event 2",
        media: {
          type: "IMAGE",
          source: {
            url: "https://picsum.photos/800/400",
          },
        },
      },
    ];

    let itemComments = [];
    if (commentsEnabled) {
      itemComments = comments
        .filter(comment => comment.postIndex === (item.id || index.toString()))
        .map((comment, commentIndex) => ({
          title: comment.name,
          //url: 'https://google.com',
          cardTitle: 'Comment added ' + (comment.isReply ? 'Just now' : `${Math.floor(Math.random() * 23) + 1} hours ago`),
          cardDetailedText: `
            <div class="timeline-comment">
            <div class="timeline-content">${comment.text}</div>
            ${!comment.isReply ? `
              <div class="timeline-reply">
                <a href="#" class="timeline-link">
                  💬 Reply to comment
                </a>
              </div>
            ` : ''}
          </div>
          `
        }));
    }

    return {
      date: new Date(item.timestamp),
      title: 'New post on ' + item.media,
      cardDetailedText: stats,
      cardTitle: item.message?.length > 100 ? item.message.substring(0, 97) + '...' : item.message,
      url: item.permalink || '',
      media: item.media && (item.s3_url || item.media_url) ? {
        type: mediaType,
        source: {
          url: item.s3_url || item.media_url,
          type: mediaType === 'VIDEO' ? 'mp4' : 'image',
        }
      } : undefined,
      items: itemComments ? itemComments : []
    };
  });

  const chronoIcons = items.map((item) => {
    const iconUrl = item.media === 'facebook' 
      ? "https://img.icons8.com/ios-filled/100/4a90e2/facebook-new.png"
      : "https://img.icons8.com/ios-filled/100/E1306C/instagram-new.png";
    
    return {
      url: iconUrl,
      className: "timeline-icon"
    };
  });

  if (chronoItems.length === 0) {
    return (
      <div className="social-timeline">
        <div className="timeline-container">
          <div style={{ 
            width: '100%', 
            height: '200px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            color: 'var(--timeline-secondary-text)',
            fontSize: '1.1em',
            textAlign: 'center',
            backgroundColor: 'var(--timeline-card-bg)',
            borderRadius: '8px',
            padding: '20px'
          }}>
            <p>No timeline items to display yet.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="social-timeline">
      <div className="timeline-container">
        <div style={{ width: '100%', height: '100%' }}>
          {isLoadingTimeline && (
            <Spinner color="primary" />
          )}
          <Chrono
            items={chronoItems}
            mode="VERTICAL_ALTERNATING"
            disableToolbar={true}
            theme={{
              primary: 'var(--timeline-link)',
              secondary: 'var(--timeline-secondary-text)',
              cardBgColor: 'var(--timeline-card-bg)',
              cardForeColor: 'var(--timeline-text)',
              titleColor: 'var(--text-primary)',
              cardTitleColor: 'var(--timeline-text)',
              cardText: 'var(--timeline-secondary-text)',
              detailsColor: 'var(--timeline-secondary-text)',
              cardDetailsColor: 'var(--timeline-secondary-text)',
              titleColorActive: 'var(--text-primary)',
              nestedCardBgColor: 'var(--timeline-card-bg)',
              nestedCardDetailsBackGround: 'var(--timeline-card-bg)',
              nestedCardDetailsColor: 'var(--timeline-secondary-text)',
              nestedCardSubtitleColor: 'var(--timeline-secondary-text)',
              nestedCardTitleColor: 'var(--timeline-text)',
            }}
            parseDetailsAsHTML={true}
            focusActiveItemOnLoad={false}
            allowDynamicUpdate={true}
          >
            <div className="chrono-icons">
              {chronoIcons.map((icon, index) => (
                <img
                  key={index}
                  src={icon.url}
                  alt={items[index].media}
                  className={icon.className}
                  style={{
                    width: '48px',
                    height: '48px',
                    marginBottom: '0px',
                    maxWidth: '48px',
                    maxHeight: '48px',
                    backgroundColor: 'var(--background-primary)'
                  }}
                />
              ))}
            </div>
          </Chrono>
        </div>
      </div>
    </div>
  );
}

export default SocialTimeline;
