import { useQuery } from "@tanstack/react-query";
import ApiService from "../../services/ApiService";
import { TENANT_BRANDS } from "../keys";
import { useSelector } from "react-redux";

const useTenantBrands = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [TENANT_BRANDS, tenant.id],
    queryFn: () => ApiService.getTenantBrands(),
    select: (data) => data.data,
    placeholderData: { data: [] },
  });
};

export default useTenantBrands;
