import React from 'react';
import { Container } from 'reactstrap';
import AudienceInsightsComponent from '../components/AudienceInsights/AudienceInsights';

const AudienceInsights = () => {
  return (
    <Container fluid className="p-4">
      <AudienceInsightsComponent />
    </Container>
  );
};

export default AudienceInsights;
