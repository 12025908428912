import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { SPONSOR_CONTRACTS } from "../keys";
import { useQuery } from "@tanstack/react-query";

const useSponsorContracts = () => {
    const tenant = useSelector((state) => state.auth.tenant);
    return useQuery({
        queryKey: [SPONSOR_CONTRACTS, tenant.id],
        queryFn: () => ApiService.getSponsorContracts(tenant.id),
        select: (data) => data.data,
        placeholderData: { data: [] },
    });
};

export default useSponsorContracts;